<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    console.log("routeeeee", this.$route);
  },
};
</script>


<style scoped>
</style>
 