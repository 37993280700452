<template>
  <div>
    <!-- 无子 -->
    <!-- {{resolvePath(item.path)}} -->
    <el-menu-item @click="onGo(resolvePath(item.path))" v-if="!hasChildren" :index="resolvePath(item.path)">
        <img style="margin-right: 8px" :src="item.meta.icon" alt="">
        <span slot="title">{{item.meta.title}}</span>
    </el-menu-item>
<!-- you -->
    <el-submenu v-else :index="resolvePath(item.path)">
      <template slot="title">
        <img style="margin-right: 8px" :src="item.meta.icon" alt="">
        <span>{{ item.meta.title }}</span>
      </template>
      <sidearItem
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
        :base-path="resolvePath(item.path)"
      >
      </sidearItem>
    </el-submenu>
  </div>
</template>

<script>
import path from 'path'

export default {
  name: 'sidearItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      d: null
    }
  },
  mounted () {
  },
  computed: {
    hasChildren () {
      let flag = true
      if (this.item.children) {
        if (this.item.children.every((item) => item.meta.sidebar === false)) {
          flag = false // 有children且有sidebar为false是返回false
        }
      } else {
        flag = false // 没有孩子也返回false
      }
      return flag
    }
  },
  methods: {
    isExternal (path) {
      return /^(https?:|mailto:|tel:)/.test(path)
    },
    onGo (routePath) {
      this.$router.push(routePath)
    },
    resolvePath (routePath) { // path拼接路由
      return path.resolve(this.basePath, routePath)
    },
    // 判断是否有孩子
    hasChild (children = [], item) {
      const showingChildren = children.filter(v => {
        if (!v) { // 判断v中是否存在
          return false //
        } else {
          this.d = v
          return true //
        }
      })
      if (showingChildren.length === 0) {
        this.d = { ...item }
        return true
      } else if (showingChildren.length === 1) { // 或者子级长度为1
        return true
      } else {
        return false
      }
    }
  }

}
</script>

<style scoped>

::v-deep .el-submenu__title:hover {
  background: #0874B4 !important;
  color: #fff !important;
}
::v-deep .el-submenu__title {
  border-radius: 5px;
}
::v-deep .el-menu-item.is-active {
  background: #EAF8FF !important;
}
</style>
