<template>
  <div style="background: #f8f9fd">
    <!-- 头部 -->
    <el-header>
      <top></top>
    </el-header>
    <!-- 下半主体部分 -->
    <el-container class="cont">
      <!-- 左侧导航 -->

<!-- nnew左 -->
      <el-aside class="aside">
        <Sidebar></Sidebar>
      </el-aside>

      <!-- 右侧main -->
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Sidebar from './components/sidebar.vue'
import top from './components/header.vue'
export default {
  data () {
    return {}
  },
  components: {
    top,
    Sidebar
  },
  mounted () {
    console.log('path', this.$route.path)
    console.log('storeee', this.$store.getters['menu/sidebarRoutes'])
  },
  methods: {}
}
</script>

<style scoped>
.el-header {
  padding: 0px;
}
.aside {
  /* box-sizing: border-box; */
  width: 215px !important;
  padding-top: 18px;
  padding-left: 14px;
  background: #fff;
}
.cont {
  margin-top: 20px;
  height: calc(100vh - 80px);
}
.cont .el-main {
  padding: 0%;
  /* height: calc(100vh - 80px); */
}
</style>
