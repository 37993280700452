<!--
 * @Description: 空白的 layout 页面
-->
<template>
  <RouterView />
</template>

<script>
export default {
  name: 'EmptyLayout',
  props: {},
  data () {
    return {}
  },
  created () {},
  mounted () {},
  methods: {}
}
</script>
