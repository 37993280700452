<template>
  <el-menu
    :default-active="$route.meta.activeMenu || $route.path"
    class="el-menu-vertical-demo, leftMenu"
    background-color="#fff"
    active-text-color="#0874B4"
  >
    <sidebarItem
      v-for="route in $store.getters['menu/sidebarRoutes']"
      :key="route.path"
      :item="route"
      :base-path="route.path"
    ></sidebarItem>

    <!-- <el-menu-item @click="onGo()">
      <i class="el-icon-question"></i>
      <span slot="title">帮助中心</span>
    </el-menu-item> -->
  </el-menu>
</template>

<script>
import cry from '@/utils/CryptoJS.js'

import sidebarItem from './sidebarItem.vue'
export default {
  name: 'Slidebar',
  components: {
    sidebarItem
  },
  data () {
    return {}
  },
  mounted () {
    console.log('ssMenu', this.$store.getters['menu/sidebarRoutes'])
  },
  methods: {
    onGo () {
      const info = JSON.parse(this.$store.state.user.info)
      const dataItem = {
        name: '骨科康复中心系统',
        code: 'HKK',
        userName: info.name,
        userMobile: info.mobile
      }
      console.log('123', info, dataItem, cry)

      const encryptData = cry.encrypt(JSON.stringify(dataItem))
      window.open('https://help.ozkoalas.cn?dataItem=' + encryptData)
    }
  }
}
</script>

<style scoped>
.leftMenu {
  width: 190px;
  height: 100%;
}
.el-menu {
  border-right: 0px !important;
}
</style>
