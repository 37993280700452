<template>
  <div class="main">
    <div>骨科康复中心后台管理系统</div>
    <div class="back">
      <el-dropdown>
        <span class="el-dropdown-link backbox">
          <div class="icon"><img src="@/assets/images/back.png" alt=""></div>
          <div>{{ info.name }}<i class="el-icon-arrow-down el-icon--right"></i></div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item> <div @click="onBack()">退出登录</div> </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      info: JSON.parse(localStorage.info)
    }
  },
  created () {
  },
  methods: {
    onBack () {
      this.$store.dispatch('user/Logout').then(() => {})
    }
  }
}
</script>

<style scoped>
.main {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 80px;
  line-height: 80px;
  background: #0874B4;
  color: #fff;
  padding-left: 33px;
  font-weight: 500;
  font-size: 24px;
}
.back {
  margin-right: 18px;
  font-size: 14px;
  font-weight: 500;
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.backbox {
  display: flex;
}
.icon {
  padding-top: 6px;
  margin-right: 9px;
  box-sizing: border-box;
}
.el-dropdown-link[data-v-ba109b4c] {
  height: 55px;
}
</style>
